import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useTheme, useMediaQuery } from '@mui/material';
import HomeStepLabel from '../step-labels/HomeStepLabel';
import ArrowStepConnector from '../step-connectors/ArrowStepConnector';
import VerticalStepConnector from '../step-connectors/VerticalStepConnector';
import HorizontalStepConnector from '../step-connectors/HorizontalStepConnector';
import HomeTutorialCompletedBox from '../boxes/HomeTutorialCompletedBox';

const HomeStepper = ({ tutorial }) => {
  const theme = useTheme();
  const isMobile = useIsMobile('desktop');
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { hook, completedText, code } = tutorial;
  const { steps, activeStep } = hook();

  const connector = useMemo(() => {
    if (isMobile) {
      return <VerticalStepConnector />;
    }
    if (isLg) {
      return <ArrowStepConnector activeStep={activeStep} />;
    }
    return <HorizontalStepConnector />;
  }, [isMobile, isLg, activeStep]);

  if (activeStep === steps.length) {
    return (
      <HomeTutorialCompletedBox text={completedText} tutorialCode={code} />
    );
  }

  return (
    <Stepper
      activeStep={activeStep}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      connector={connector}
      sx={{
        border: { lg: '1px solid' },
        borderColor: { lg: 'gray.A300' },
        borderRadius: { lg: 1 },
      }}
    >
      {steps.map(({ id, title, description, onClick }, index) => (
        <Step
          key={id}
          onClick={() => activeStep === index && onClick()}
          sx={{
            px: { lg: 3 },
            pr: { lg: 0 },
            py: { lg: 2 },
            flex: { lg: 1 },
            backgroundColor: {
              lg: activeStep === index ? 'primary.main' : 'common.white',
            },
            cursor: { desktop: activeStep === index ? 'pointer' : 'default' },
            '&.Mui-completed': {
              backgroundColor: { lg: 'primary.A100' },
            },
          }}
        >
          <HomeStepLabel title={title} description={description} />
        </Step>
      ))}
    </Stepper>
  );
};

HomeStepper.propTypes = {
  tutorial: PropTypes.shape({
    title: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    hook: PropTypes.func.isRequired,
    completedText: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomeStepper;
