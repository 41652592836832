import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useHomeTutorial from '../../hooks/useHomeTutorial';
import HomeStepper from '../steppers/HomeStepper';

const HomeTutorial = () => {
  const { currentTutorial } = useHomeTutorial();

  if (!currentTutorial) return null;

  return (
    <Stack spacing={2}>
      <Typography variant="medium_2" color="gray.A700" fontWeight={600}>
        {currentTutorial.title}
      </Typography>
      <Box height={{ mobile: 'auto', lg: 72 }}>
        <HomeStepper tutorial={currentTutorial} />
      </Box>
    </Stack>
  );
};

export default HomeTutorial;
